import {createRouter, createWebHashHistory} from 'vue-router'

const routeList = [
    {
        path: '/',
        component: () => import('@/components/LoginView'),
    },
    {
        path: '/login',
        component: () => import('@/components/LoginView'),
    },
    {
        path: '/register',
        component: () => import('@/components/RegisterView'),
    },
    {
        path: '/forgetPwd',
        component: () => import('@/components/ForgetPwdView'),
    },
    {
        path: '/modifyPwd',
        component: () => import('@/components/ModifyPwdView'),
    },
    {
        path: '/modifyBasePwd',
        component: () => import('@/components/ModifyBasePwdView'),
    },
    {
        path: '/modifyNickName',
        component: () => import('@/components/ModifyNickNameView'),
    },
    {
        path: '/promoterDetail',
        component: () => import('@/components/PromoterDetailView'),
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/subUserDetail',
        component: () => import("@/components/promoter/SubUserDetailView"),
    },
    {
        path: '/userKyc',
        component: () => import('@/components/UserKycView'),
    },
    {
        path: '/setting',
        component: () => import('@/components/SettingView'),
    },
    {
        path: '/recharge',
        component: () => import('@/components/RechargeView'),
    },
    {
        path: '/rechargeQr',
        component: () => import('@/components/RechargeQrView'),
    },
    {
        path: '/rechargeResult',
        component: () => import('@/components/RechargeResultView'),
    },
    {
        path: '/rechargeDetail',
        component: () => import('@/components/RechargeDetailView'),
    },
    {
        path: '/rechargeForm',
        name: 'rechargeForm',
        component: () => import('@/components/RechargeFormView'),
    },
    {
        path: '/withdraw',
        component: () => import('@/components/WithdrawView'),
    },
    {
        path: '/transUser',
        component: () => import('@/components/TransferAmountView.vue'),
    },
    {
        path: '/withdrawAcc',
        component: () => import('@/components/WithdrawAccView'),
    },
    {
        path: '/withdrawHistory',
        component: () => import('@/components/WithdrawHistoryView'),
    },
    {
        path: '/withdrawDetail',
        component: () => import('@/components/WithdrawDetailView'),
    },
    {
        path: '/userProfile',
        component: () => import('@/components/UserProfileView'),
    },
    {
        path: '/myBonus',
        component: () => import('@/components/BonusView'),
    },
    {
        path: '/traceNum',
        component: () => import('@/components/TraceNumView'),
    },
    {
        path: '/numBasket',
        component: () => import('@/components/NumBasketView'),
    },
    {
        path: '/myMessage',
        component: () => import('@/components/MessageView'),
    },
    {
        path: '/myRecommend',
        component: () => import('@/components/MyRecommendView'),
    },
    {
        path: '/shopMsg',
        component: () => import('@/components/ShopMessageView'),
    },
    {
        path: '/shopProfile',
        component: () => import('@/components/ShopProfileView'),
    },
    {
        path: '/invite',
        component: () => import('@/components/InviteUserView'),
    },
    {
        path: '/balanceHistory',
        component: () => import('@/components/BalanceHistoryView'),
    },
    {
        path: '/orderHistory',
        component: () => import('@/components/OrderHistoryView'),
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/helpCenter',
        component: () => import('@/components/HelpCenterView'),
    },
    {
        path: '/accSafe',
        component: () => import('@/components/helpCenter/AccountSafeHelp'),
    },
    {
        path: '/loginReg',
        component: () => import('@/components/helpCenter/LoginRegHelp'),
    },
    {
        path: '/buyHelp',
        component: () => import('@/components/helpCenter/BuyHelp'),
    },
    {
        path: '/financeHelp',
        component: () => import('@/components/helpCenter/FinanceHelp'),
    },
    {
        path: '/otherHelp',
        component: () => import('@/components/helpCenter/OtherHelp'),
    },
    {
        path: '/playHelp',
        component: () => import('@/components/helpCenter/PlayDescHelp'),
    },
    {
        path: '/serviceCenter',
        component: () => import('@/components/ServiceCenterView'),
    },
    {
        path: '/followOrderView',
        component: () => import('@/components/follow/FollowOrderView'),
    },
    {
        path: '/followQuestion',
        component: () => import('@/components/follow/FollowQuestionView'),
    },
    {

        path: '/followOrderDetail',
        component: () => import('@/components/follow/FollowOrderDetail'),
    },
    {
        path: '/searchFollowUser',
        component: () => import('@/components/follow/SearchFollowUser'),
    },
    {
        path: '/userFollowDetail',
        component: () => import('@/components/follow/UserFollowDetail'),
    },
    {
        path: '/myFollowDetail',
        component: () => import('@/components/follow/MyFollowDetail'),
    },
    {
        path: '/allFollowRank',
        component: () => import('@/components/follow/AllFollowRankView'),
    },
    // 竞彩足球
    {
        path: '/football',
        component: () => import('@/components/football/FootballView'),
    },
    {
        path: '/footballConfirm',
        component: () => import('@/components/football/FootballConfirmView'),
    },
    {
        path: '/footballOrder',
        component: () => import('@/components/football/FootballOrder'),
    },
    {
        path: '/footballOptimize',
        component: () => import('@/components/football/FootballOptimizeView'),
    },
    // 竞彩篮球
    {
        path: '/basketball',
        component: () => import('@/components/basketball/BasketballView'),
    },
    {
        path: '/basketballOrder',
        component: () => import('@/components/basketball/BasketballOrder'),
    },
    {
        path: '/basketballConfirm',
        component: () => import('@/components/basketball/BasketballConfirmView'),
    },
    {
        path: '/basketballOptimize',
        component: () => import('@/components/basketball/BasketballOptimizeView'),
    },
    // 北京单场
    {
        path: '/bjSingle',
        component: () => import('@/components/beijing/BeijingView'),
    },
    {
        path: '/bjOrder',
        component: () => import('@/components/beijing/BeijingOrder'),
    },
    {
        path: '/bjConfirm',
        component: () => import('@/components/beijing/BeijingConfirmView'),
    },
    // 传统足球
    {
        path: '/tradition',
        component: () => import('@/components/tradition/TraditionView'),
    },
    {
        path: '/traditionOrder',
        component: () => import('@/components/tradition/TraditionOrder'),
    },
    {
        path: '/traditionConfirm',
        component: () => import('@/components/tradition/TraditionConfirmView'),
    },
    // 超级大乐透
    {
        path: '/bigLottery',
        component: () => import('@/components/bigLottery/BigLotteryView'),
    },
    {
        path: '/bigLotteryOpen',
        component: () => import('@/components/bigLottery/BigLotteryOpen'),
    },
    {
        path: '/bigLotteryOrder',
        component: () => import('@/components/bigLottery/BigLotteryOrder'),
    },
    {
        path: '/bigLotteryConfirm',
        component: () => import('@/components/bigLottery/BigLotteryConfirm'),
    },
    // 七星彩
    {
        path: '/sevenStar',
        component: () => import('@/components/sevenStar/SevenStarView'),
    },
    {
        path: '/sevenStarOpen',
        component: () => import('@/components/sevenStar/SevenStarOpen'),
    },
    {
        path: '/sevenStarOrder',
        component: () => import('@/components/sevenStar/SevenStarOrder'),
    },
    {
        path: '/sevenStarConfirm',
        component: () => import('@/components/sevenStar/SevenStarConfirm'),
    },
    // 排列三
    {
        path: '/rankNumThree',
        component: () => import('@/components/rankNum/RankNumThreeView'),
    },
    {
        path: '/rankThreeOrder',
        component: () => import('@/components/rankNum/RankNumThreeOrder'),
    },
    {
        path: '/rankThreeOpen',
        component: () => import('@/components/rankNum/RankNumThreeOpen'),
    },
    {
        path: '/rankThreeConfirm',
        component: () => import('@/components/rankNum/RankNumThreeConfirm'),
    },
    // 排列五
    {
        path: '/rankNumFive',
        component: () => import('@/components/rankNum/RankNumFiveView'),
    },
    {
        path: '/rankFiveOpen',
        component: () => import('@/components/rankNum/RankNumFiveOpen'),
    },
    {
        path: '/rankFiveOrder',
        component: () => import('@/components/rankNum/RankNumFiveOrder'),
    },
    {
        path: '/rankFiveConfirm',
        component: () => import('@/components/rankNum/RankNumFiveConfirm'),
    },
    // 修改跟单数据
    {
        path: '/followMod',
        component: () => import('@/components/mod/FollowModView'),
    },
    {
        path: '/chatDetail',
        component: () => import('@/components/chat/ChatDetail'),
    },
    {
        path: '/chatGroup',
        component: () => import('@/components/chat/ChatGroup'),
    },
    {
        path: '/chatList',
        component: () => import('@/components/chat/ChatList'),
    },
    {
        path: '/myChatGroup',
        component: () => import('@/components/chat/MyChatGroupView.vue'),
    },
    {
        path: '/groupInfo',
        component: () => import('@/components/chat/GroupInfoView.vue'),
    },
    {
        path: '/groupMemberInfo',
        component: () => import('@/components/chat/GroupMemberInfo.vue'),
    },
    {
        path: '/selectFriend',
        component: () => import('@/components/chat/SelectFriendView.vue'),
    },
    {
        path: '/systemMsg',
        component: () => import('@/components/chat/SystemMsgView.vue'),
    },
    {
        path: '/newFriend',
        component: () => import('@/components/chat/NewFriendView.vue'),
    },
    {
        path: '/addFriend',
        component: () => import('@/components/chat/AddFriendView.vue'),
    },
    {
        path: '/groupSend',
        component: () => import('@/components/chat/GroupSendView.vue'),
    },
    {
        path: '/friendList',
        component: () => import('@/components/chat/FriendListView.vue'),
    },
    {
        path: '/friendInfo',
        component: () => import('@/components/chat/FriendInfoView.vue'),
    },
    {
        path: '/bindJoinPayAcc',
        component: () => import('@/components/payment/BindJoinPayAccount'),
    },
    {
        path: '/index',
        component: () => import("@/components/MainLayout"),
        children: [
            {
                path: '',
                name: '/home',
                component: () => import("@/components/HomeView"),
            },
            {
                path: '/home',
                component: () => import("@/components/HomeView"),
            },
            {
                path: '/lottery',
                component: () => import("@/components/LotteryView"),
            },
            {
                path: '/my',
                component: () => import("@/components/MyView"),
            },
            {
                path: '/score',
                component: () => import("@/components/SportHistoryView"),
            },
        ]
    },
    {
        path: '/store',
        component: () => import('@/components/StoreView'),
    },
]

const Router = createRouter({
    history: createWebHashHistory(),
    routes: routeList,
});

export default Router;
