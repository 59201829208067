import Vuex from 'vuex';
import * as types from './types';

const Store = new Vuex.Store({
    state: {
        user: {},
        shopInfo: {},
        footballMatchList: [],
        basketballMatchList: [],
        beijingMatchList: [],
        confirmFootball: [],
        confirmBeijing: [],
        confirmTradition: {},
        confirmBasketball: [],
        confirmSuperLot: {
            batchNo: '',
            betList: [],
            multiple: 1,
        },
        confirmSevenStar: {
            batchNo: '',
            betList: [],
            multiple: 1,
        },
        confirmRankThree: {
            batchNo: '',
            betList: [],
            multiple: 1,
        },
        confirmRankFive: {
            batchNo: '',
            betList: [],
            multiple: 1,
        },
        subUserDetail: {},
        withdrawDetail: {},
        followBetData: {},
        followOrder: {},
        token: null,
        channelList: [],
        rechargeForm: "",
        currChatItem: {},
        currGroupItem: {},
        friendInfo: {},
        unReadMsgCount: 0,
        groupMemberList: [],
        allChatList: [],
        allGroupList: [],
        allFriendList: [],
    },
    mutations: {
        [types.TOKEN]: (state, data) => {
            localStorage.token = data;
            state.token = data;
            sessionStorage.setItem(types.TOKEN, data);
            localStorage.setItem(types.TOKEN, data);
        },
        [types.LOGOUT]: (state) => {
            state.token = null;
            localStorage.removeItem(types.TOKEN);
            sessionStorage.removeItem(types.TOKEN);
        },
        [types.USER_INFO]: (state, data) => {
            localStorage.user = data;
            state.user = data;
            sessionStorage.setItem(types.USER_INFO, JSON.stringify(data));
            localStorage.setItem(types.USER_INFO, JSON.stringify(data));
        },
        [types.SHOP_INFO]: (state, data) => {
            localStorage.shopInfo = data;
            state.shopInfo = data;
            sessionStorage.setItem(types.SHOP_INFO, JSON.stringify(data));
            localStorage.setItem(types.SHOP_INFO, JSON.stringify(data));
        },
        [types.CONFIRM_FOOTBALL]: (state, data) => {
            localStorage.confirmFootball = data;
            state.confirmFootball = data;
            sessionStorage.setItem(types.CONFIRM_FOOTBALL, JSON.stringify(data));
        },
        [types.CONFIRM_TRADITION]: (state, data) => {
            localStorage.confirmTradition = data;
            state.confirmTradition = data;
            sessionStorage.setItem(types.CONFIRM_TRADITION, JSON.stringify(data));
        },
        [types.CONFIRM_BEIJING]: (state, data) => {
            localStorage.confirmBeijing = data;
            state.confirmBeijing = data;
            sessionStorage.setItem(types.CONFIRM_BEIJING, JSON.stringify(data));
        },
        [types.CONFIRM_BASKETBALL]: (state, data) => {
            localStorage.confirmBasketball = data;
            state.confirmBasketball = data;
            sessionStorage.setItem(types.CONFIRM_BASKETBALL, JSON.stringify(data));
        },
        [types.CONFIRM_SUPER_LOT]: (state, data) => {
            localStorage.confirmSuperLot = data;
            state.confirmSuperLot = data;
            sessionStorage.setItem(types.CONFIRM_SUPER_LOT, JSON.stringify(data));
        },
        [types.CONFIRM_SEVEN_STAR]: (state, data) => {
            localStorage.confirmSevenStar = data;
            state.confirmSevenStar = data;
            sessionStorage.setItem(types.CONFIRM_SEVEN_STAR, JSON.stringify(data));
        },
        [types.CONFIRM_RANK_THREE]: (state, data) => {
            localStorage.confirmRankThree = data;
            state.confirmRankThree = data;
            sessionStorage.setItem(types.CONFIRM_RANK_THREE, JSON.stringify(data));
        },
        [types.CONFIRM_RANK_FIVE]: (state, data) => {
            localStorage.confirmRankFive = data;
            state.confirmRankFive = data;
            sessionStorage.setItem(types.CONFIRM_RANK_FIVE, JSON.stringify(data));
        },
        [types.WITHDRAW_DETAIL]: (state, data) => {
            localStorage.withdrawDetail = data;
            state.withdrawDetail = data;
            sessionStorage.setItem(types.WITHDRAW_DETAIL, JSON.stringify(data));
        },
        [types.SUB_USER_DETAIL]: (state, data) => {
            localStorage.subUserDetail = data;
            state.subUserDetail = data;
            sessionStorage.setItem(types.SUB_USER_DETAIL, JSON.stringify(data));
        },
        [types.FOOTBALL_MATCH_LIST]: (state, data) => {
            localStorage.footballMatchList = data;
            state.footballMatchList = data;
            sessionStorage.setItem(types.FOOTBALL_MATCH_LIST, JSON.stringify(data));
        },
        [types.BASKETBALL_MATCH_LIST]: (state, data) => {
            localStorage.basketballMatchList = data;
            state.basketballMatchList = data;
            sessionStorage.setItem(types.BASKETBALL_MATCH_LIST, JSON.stringify(data));
        },
        [types.BEIJING_MATCH_LIST]: (state, data) => {
            localStorage.beijingMatchList = data;
            state.beijingMatchList = data;
            sessionStorage.setItem(types.BEIJING_MATCH_LIST, JSON.stringify(data));
        },
        [types.FOLLOW_BET_DATA]: (state, data) => {
            localStorage.followBetData = data;
            state.followBetData = data;
            sessionStorage.setItem(types.FOLLOW_BET_DATA, JSON.stringify(data));
        },
        [types.FOLLOW_ORDER]: (state, data) => {
            localStorage.followOrder = data;
            state.followOrder = data;
            sessionStorage.setItem(types.FOLLOW_ORDER, JSON.stringify(data));
        },
        [types.RECHARGE_CHANNEL]: (state, data) => {
            localStorage.channelList = data;
            state.channelList = data;
            sessionStorage.setItem(types.RECHARGE_CHANNEL, JSON.stringify(data));
        },
        [types.RECHARGE_FORM]: (state, data) => {
            localStorage.rechargeForm = data;
            state.rechargeForm = data;
            sessionStorage.setItem(types.RECHARGE_FORM, data);
        },
        [types.CURRENT_CHAT_ITEM]: (state, data) => {
            localStorage.currChatItem = data;
            state.currChatItem = data;
            sessionStorage.setItem(types.CURRENT_CHAT_ITEM, JSON.stringify(data));
        },
        [types.CURRENT_GROUP_ITEM]: (state, data) => {
            localStorage.currGroupItem = data;
            state.currGroupItem = data;
            sessionStorage.setItem(types.CURRENT_GROUP_ITEM, JSON.stringify(data));
        },
        [types.FRIEND_INFO]: (state, data) => {
            localStorage.friendInfo = data;
            state.friendInfo = data;
            sessionStorage.setItem(types.FRIEND_INFO, JSON.stringify(data));
        },
        [types.GROUP_MEMBER_LIST]: (state, data) => {
            localStorage.groupMemberList = data;
            state.groupMemberList = data;
            sessionStorage.setItem(types.GROUP_MEMBER_LIST, JSON.stringify(data));
        },
        [types.ALL_CHAT_LIST]: (state, data) => {
            localStorage.allChatList = data;
            state.allChatList = data;
            sessionStorage.setItem(types.ALL_CHAT_LIST, JSON.stringify(data));
        },
        [types.ALL_GROUP_LIST]: (state, data) => {
            localStorage.allGroupList = data;
            state.allGroupList = data;
            sessionStorage.setItem(types.ALL_GROUP_LIST, JSON.stringify(data));
        },
        [types.ALL_FRIEND_LIST]: (state, data) => {
            localStorage.allFriendList = data;
            state.allFriendList = data;
            sessionStorage.setItem(types.ALL_FRIEND_LIST, JSON.stringify(data));
        },
        [types.UNREAD_MSG_COUNT]: (state, data) => {
            localStorage.unReadMsgCount = data;
            state.unReadMsgCount = data;
            sessionStorage.setItem(types.UNREAD_MSG_COUNT, data);
        },
    }
});
export default Store;
