import {createApp} from 'vue';
import 'vant/lib/index.css';

import {Button} from 'vant';
import {Cell, Field, CellGroup} from 'vant';
import {Col, Row} from 'vant';
import {Form} from "vant";
import {Tabbar, TabbarItem} from 'vant';
import {Image as VanImage} from 'vant';
import {NoticeBar} from "vant";
import {Loading} from 'vant';
import {NavBar} from 'vant';
import {Notify} from 'vant';
import {Toast} from 'vant';
import {Dialog} from 'vant';
import {Divider} from 'vant';
import {Icon} from "vant";
import {Badge} from "vant";
import {Tab, Tabs} from 'vant';
import {Empty} from 'vant';
import {List} from 'vant';
import {Stepper} from 'vant';
import {Tag} from 'vant';
import {SwipeCell} from 'vant';
import {Swipe, SwipeItem} from 'vant';
import {Grid, GridItem} from 'vant';
import {Popover} from "vant";
import {Search} from 'vant';
import {Slider} from 'vant';
import {Radio, RadioGroup} from "vant";
import {Checkbox, CheckboxGroup} from 'vant';
import {Collapse, CollapseItem} from "vant";
import {PullRefresh} from 'vant';
import {Popup} from "vant";
import {Picker} from "vant";
import {Switch} from 'vant';
import {Calendar} from 'vant';
import {Skeleton} from 'vant';
import {Uploader} from "vant";
import {NumberKeyboard} from 'vant';
import {DropdownMenu, DropdownItem} from 'vant';
import {ImagePreview} from 'vant';
import {Rate} from 'vant';
import {Space} from 'vant';
import {SubmitBar} from 'vant';
import {FloatingPanel} from 'vant';
import {DatePicker} from 'vant';
import {TextEllipsis} from 'vant';
import {TreeSelect} from 'vant';
import {Overlay} from 'vant';
import {TimePicker} from 'vant';
import App from './App.vue'

import VueClipboard from 'vue-clipboard2';
import particles from "particles.js";
import Router from "./router/router";
import Init from './store/init';
import '@/styles/view2.css';

import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

import VueQr from 'vue-qr';
import {OpenIMSDK} from "open-im-sdk";

Date.prototype.Format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}


const app = createApp(App);

app.use(particles);
app.use(VXETable);
app.use(VueQr);

app.use(Button);
app.use(Field);
app.use(CellGroup);
app.use(Col);
app.use(Row);
app.use(Rate);
app.use(Form);
app.use(Tabbar);
app.use(Space);
app.use(TabbarItem);
app.use(VanImage);
app.use(Slider);
app.use(Calendar);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Calendar);
app.use(Cell);
app.use(Popover);
app.use(NoticeBar);
app.use(NavBar);
app.use(Loading);
app.use(Notify);
app.use(Toast);
app.use(Dialog);
app.use(Divider);
app.use(Icon);
app.use(Collapse);
app.use(FloatingPanel);
app.use(CollapseItem);
app.use(Badge);
app.use(Tab);
app.use(Tabs);
app.use(Switch);
app.use(Empty);
app.use(PullRefresh);
app.use(List);
app.use(Stepper);
app.use(Tag);
app.use(SwipeCell);
app.use(Search);
app.use(RadioGroup);
app.use(Radio);
app.use(Picker);
app.use(Popup);
app.use(Skeleton);
app.use(Grid);
app.use(GridItem);
app.use(Swipe);
app.use(SwipeItem);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Uploader);
app.use(NumberKeyboard);
app.use(ImagePreview);
app.use(SubmitBar);
app.use(TextEllipsis);
app.use(Overlay);
app.use(DatePicker);
app.use(TreeSelect);
app.use(TimePicker);

app.use(Router);
app.use(VueClipboard);

app.config.globalProperties.$OPENIM = new OpenIMSDK();

Init.initStorage();
app.mount('#app');
